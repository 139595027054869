import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@views/layout/index.vue";
import { decryptData } from "@/utils/AES.js";
Vue.use(VueRouter);

const NotFound = () => import("@/views/404");

const routes = [
  { path: "/", name: "home", redirect: { name: "Home" } }, // 默认页面
  { path: "/login", name: "Login", component: () => import("@views/Login/index.vue")}, // 默认页面
  { path: "/experience", name: "experience", component: () => import("@views/experience.vue") },
  { path: "/usermag", name: "usermag", component: () => import("@views/usermag.vue") },
  { path: "/role", name: "role", component: () => import("@views/Login/role.vue")},
  { path: "/wxpay", name: "wxpay", component: () => import("@views/Pay/wxpaycode.vue") },
  { path: "/payresult", name: "payresult", component: () => import("@views/Pay/payResult.vue") },
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: { name: "Home" },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@views/Home/index.vue"),
      },

    ],
  },

  // 404
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  console.log('token', to.query)
  const params = to.query;
  console.log(params)
  if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
    if (token) { // 通过vuex state获取当前的token是否存在
      next()
    } else if (params.sign) {
      // const param = JSON.parse(decryptData(params.sign.replace(new RegExp(/( )/g), '+')))
      // console.log(param)
      // localStorage.setItem('userInfo', JSON.stringify({
      //   "id": 2,
      //   "phone": param.id,
      //   "wx_openid": null,
      //   "password": null,
      //   "head": null,
      //   "nickname": 'AI智能鼠标',
      //   "vip_time": null,
      //   "update_time": new Date().getTime(),
      //   "create_time": new Date().getTime(),
      //   "token": "b9fd8b354a6238afe3b08c392f1b3f5bfadfad",
      //   "industry_code": null,
      //   "industry": null,
      //   "time": new Date().getTime()
      // }));
      next({
        path: '/home'
        // query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    } else {
      next({
        path: '/login'
        // query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})
export default router;
